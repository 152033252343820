import styled from 'styled-components'

interface IProps {
	title: string
	subtitle: string
}

const FooterItem: React.FC<IProps> = ({ title, subtitle }) => {
	return (
		<StyledFooter>
			<FooterTitle>{title}</FooterTitle>
			<FooterSubtitle>{subtitle}</FooterSubtitle>
		</StyledFooter>
	)
}

const FooterTitle = styled.p`
	font-size: 13px;
	font-weight: 800;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 5px;
	margin-top: 0;
`
const FooterSubtitle = styled.p`
	font-size: 13px;
	text-align: left;
	margin: 0;
`

const StyledFooter = styled.div`
	margin: 0 0 8px 0;
	&:last-child {
		margin-bottom: 0;
	}
`

export default FooterItem
