import { useEffect, useState } from 'react'
import styled from 'styled-components'

type AlertType = 'success' | 'error'

interface IProps {
	type: AlertType
	message: string
	timeout?: number
}

const Alert: React.FC<IProps> = ({ type, message, timeout }) => {
	const [visible, setVisible] = useState(true)

	useEffect(() => {
		if (timeout) {
			const timer = setTimeout(() => {
				setVisible(false)
			}, timeout)

			return () => clearTimeout(timer)
		}
	}, [timeout])

	return visible ? (
		<AlertContainer type={type}>
			<AlertMessage>{message}</AlertMessage>
		</AlertContainer>
	) : null
}

const AlertContainer = styled.div<Omit<IProps, 'message' | 'timeout'>>`
	position: absolute;
	left: 0;
	z-index: 2;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 12px 0px;
	background-color: ${(props) => (props.type === 'success' ? '#d4edda' : '#f8d7da')};
	color: ${(props) => (props.type === 'success' ? '#155724' : '#721c24')};
	border: 1px solid ${(props) => (props.type === 'success' ? '#c3e6cb' : '#f5c6cb')};
`

const AlertMessage = styled.span`
	flex-grow: 1;
	padding-left: 10px;
`

export default Alert
