export const TOKEN_LENGTH = 32
export const TOKEN_ELEMENT_ID_VALUE = 'seif-token-'

export const checkIfTokenInHTML = (html: string) => {
	const tokenIndex = html.indexOf(TOKEN_ELEMENT_ID_VALUE)

	if (tokenIndex !== -1) {
		const tokenStartIndex = tokenIndex + TOKEN_ELEMENT_ID_VALUE.length
		const tokenEndIndex = tokenStartIndex + TOKEN_LENGTH

		const token = html.substring(tokenStartIndex, tokenEndIndex)

		return token
	}
}
