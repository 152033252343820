import React from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'
import { respondTo } from '../styles/breakpoints'
import Error from './Error'

export type IInputValue = string | number
export type IInputType = 'text' | 'number' | 'email' | 'password' | 'date' | 'search'

interface IInputData {
	name: string
	value: IInputValue
}

export interface IInputProps {
	defaultValue: IInputValue
	error?: string
	label?: string
	labelHelpInfo?: string
	additionalLink?: string
	required?: boolean
	name: string
	icon?: React.ReactElement
	type?: IInputType
	value?: IInputValue
	placeholder?: string
	disabled?: boolean
	hideError?: boolean
	hidableIcon?: boolean
	autoFocus?: boolean
	onChange?(event: React.ChangeEvent<HTMLInputElement>, data: IInputData): void
	onFocus?(event: React.FocusEvent<HTMLInputElement>): void
	onBlur?(event: React.FocusEvent<HTMLInputElement>): void
	onKeyDown?(event: React.KeyboardEvent): void
}

interface IWrapperProps {
	active: boolean
	hasValue: boolean
	hasError?: boolean
	icon?: boolean
	hidableIcon?: boolean
}

const ErrorCss = css`
	input,
	textarea {
		border: 1px solid ${Colors.Red};
		border-color: ${Colors.Red};
	}
	.error {
		color: ${Colors.Red};
	}
`

const getBorderColor = (props: IWrapperProps) => {
	if (props.active) {
		return `${Colors.Blue}`
	} else if (props.hasValue) {
		return `${Colors.Light}`
	}
	return `${Colors.Light}`
}

const getInputTypeIcon = (props: IWrapperProps) => {
	if (props.hidableIcon) {
		if (props.active || props.hasValue) {
			return 0
		}
	}
	return 1
}

export const Wrapper = styled.div<IWrapperProps>`
	position: relative;
	margin-bottom: ${SPACES.DEFAULT};
	transition: all 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	input,
	textarea {
		height: 32px;
		width: 290px;
		box-shadow: ${(props) =>
			props.active && !props.hasError ? `0 0 0 2px ${rgba(`${Colors.Blue}`, 0.24)}` : `none`};
		background-color: transparent;
		border: 1px solid ${(props) => getBorderColor(props)};
		border-radius: 10px;
		padding: 10px ${SPACES.LARGE} 10px ${SPACES.DEFAULT};
		outline: none;
		filter: none;
		font-size: 15px;
		color: ${(props) => (props.active || props.hasValue ? `${Colors.Black}` : `${Colors.Light}`)};
		text-align: center;
		-webkit-appearance: none;
		transition: all 0.3s ease-in-out;
		&:disabled {
			pointer-events: none;
			opacity: 0.4;
		}
		::placeholder {
			opacity: 1; /* Firefox */
		}
		::-webkit-search-cancel-button {
			display: none;
		}
		${respondTo.xs`
 			font-size: 14px;
			padding: ${SPACES.EXTRA_SMALL};
	`}
	}
	textarea {
		resize: none;
		overflow: hidden;
	}
	label {
		position: absolute;
		top: -20px;
		left: 0;
		font-size: 12px;
		line-height: normal;
		color: ${Colors.Dark};
		${respondTo.xs`
			letter-spacing: -0.2px;
		`}
		strong {
			color: ${Colors.Red};
		}
	}
	i {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 12px;
		color: ${Colors.Light};
		opacity: ${(props) => getInputTypeIcon(props)};
	}
	span,
	.error {
		position: absolute;
		top: 100%;
		left: 0;
		font-size: 10px;
		line-height: 12px;
		color: ${Colors.Dark};
	}
	&:hover {
		input,
		textarea {
			border-color: ${(props) => (props.hasError ? Colors.Red : Colors.Blue)};
			&:disabled {
				border-color: ${Colors.Light};
			}
		}
	}
	${(props) => (props.hasError ? ErrorCss : null)}
`

const Input: React.FC<IInputProps> = ({
	defaultValue,
	error,
	label,
	required,
	type,
	icon,
	value,
	name,
	placeholder,
	disabled,
	hideError,
	hidableIcon,
	autoFocus,
	onFocus,
	onBlur,
	onChange,
	onKeyDown,
}) => {
	const [active, setActive] = React.useState(false)

	// Local value if component is not managed by parent
	const [localValue, setLocalValue] = React.useState(defaultValue || '')

	// Check if component is managed by parent
	const realValue = value !== undefined ? value : localValue

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalValue(event.target.value)
		if (onChange) {
			onChange(event, { name, value: event.target.value })
		}
	}

	const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		setActive(true)
		if (onFocus) {
			onFocus(event)
		}
	}

	const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		setActive(false)
		if (onBlur) {
			onBlur(event)
		}
	}

	return (
		<Wrapper
			active={active}
			hasValue={!!realValue}
			hasError={!!error}
			icon={!!icon}
			hidableIcon={hidableIcon}
		>
			<input
				autoFocus={autoFocus}
				type={type}
				value={realValue}
				onChange={handleChange}
				onBlur={handleInputBlur}
				onFocus={handleInputFocus}
				name={name}
				placeholder={placeholder}
				disabled={disabled}
				onKeyDown={onKeyDown}
			/>
			{icon && icon}
			{label && (
				<label>
					{label} {required && <strong>*</strong>}{' '}
				</label>
			)}
			{error && !hideError && <Error text={error} />}
		</Wrapper>
	)
}

Input.defaultProps = {
	type: 'text',
	autoFocus: false,
}

export default Input
