import { useMutation } from 'react-query'

import { generateToken as generateTokenApi, GENERATE_TOKEN_KEY } from '../utils/api'

const useGenerateToken = () => {
	const {
		mutate: generateToken,
		isLoading: isGeneratingToken,
		data: email,
		error: errorGeneratingToken,
	} = useMutation(generateTokenApi, {
		mutationKey: GENERATE_TOKEN_KEY,
	})

	return {
		generateToken,
		isGeneratingToken,
		email,
		errorGeneratingToken,
	}
}

export default useGenerateToken
