import * as React from 'react'
import styled from 'styled-components'

interface IProps {
	text?: string
}
const ErrorMsg = styled.span``

const Error: React.FC<IProps> = ({ text }) => <ErrorMsg className="error">{text}</ErrorMsg>

export default Error
