import React from 'react'
import styled from 'styled-components'
import { lighten, rgba } from 'polished'
import { Link } from 'react-router-dom'

import { Colors } from '../styles/colors'
import { SPACES } from '../styles/spaces'
import { respondTo } from '../styles/breakpoints'

export interface IButtonProps {
	primary?: boolean
	secondary?: boolean
	disabled?: boolean
	danger?: boolean
	radius?: boolean
	transparent?: boolean
	link?: boolean
	id?: string
	type?: 'button' | 'submit' | 'reset'
	onClick?: React.MouseEventHandler<HTMLElement>
	href?: string
	small?: boolean
	icon?: React.ReactElement
	children?: any
	padding?: string
	margin?: string
}

const getColor = (props: IButtonProps) => {
	if (props.primary || props.danger) {
		return `${Colors.White}`
	}
	return `${Colors.Dark}`
}

const getHoverColor = (props: IButtonProps) => {
	if (props.primary || props.danger) {
		return `${Colors.White}`
	}
	if (props.secondary) {
		return `${Colors.Dark}`
	}
	return `${Colors.GrayDark}`
}

const getColorDisabled = (props: IButtonProps) => {
	if (props.primary || props.danger) {
		return `${Colors.White}`
	}
	return `${rgba(`${Colors.Dark}`, 0.4)}`
}

const getBackgroundColor = (props: IButtonProps) => {
	if (props.primary) {
		return `${Colors.Blue}`
	}
	if (props.secondary) {
		return Colors.GrayLighter
	}
	if (props.danger) {
		return Colors.Red
	}
	return 'transparent'
}

const getHoverBackgroundColor = (props: IButtonProps) => {
	if (props.primary) {
		return `${lighten(0.06, `${Colors.Blue}`)}`
	}
	if (props.secondary) {
		return `${rgba(`${Colors.GrayLighter}`, 0.7)}`
	}
	if (props.danger) {
		return rgba(`${Colors.Red}`, 0.8)
	}
	if (props.transparent) {
		return 'transparent'
	}
	return `${Colors.Blue}`
}

const getFocusBackgroundColor = (props: IButtonProps) => {
	if (props.primary) {
		return `${lighten(0.1, `${Colors.Blue}`)}`
	}
	if (props.secondary) {
		return `${rgba(`${Colors.GrayLighter}`, 0.5)}`
	}
	if (props.danger) {
		return rgba(`${Colors.Red}`, 0.8)
	}
	return `${lighten(0.1, `${Colors.Blue}`)}`
}

const getDisabledBackgroundColor = (props: IButtonProps) => {
	if (props.primary) {
		return `${rgba(`${Colors.Blue}`, 0.5)}`
	}
	if (props.secondary) {
		return `${rgba(`${Colors.GrayLighter}`, 0.5)}`
	}
	return `transparent`
}

const StyledButton = styled.button<IButtonProps>`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: auto;
	min-width: ${(props) => (props.small ? '10px' : '196px')};
	min-height: ${(props) => (props.small ? `${SPACES.EXTRA_SMALL}` : '40px')};
	border-radius: ${(props) => (props.radius ? `100px` : `6px`)};
	border: none;
	padding: ${(props) => props.padding || `0 ${SPACES.SMALL}`};
	margin: ${(props) => props.margin || `0 ${SPACES.SMALL}`};
	background-color: ${(props) => getBackgroundColor(props)};
	font-size: ${(props) => (props.small ? `12px` : '15px')};
	font-weight: 500;
	text-align: center;
	text-decoration: none;
	color: ${(props) => getColor(props)};
	word-break: break-all;
	transition: all 0.3s ease-in-out;
	${respondTo.xs`
		font-size: ${(props: any) => (props.small ? `12px` : '14px')};
	`}
	&:hover {
		background-color: ${(props) => getHoverBackgroundColor(props)};
		color: ${(props) => getHoverColor(props)};
		cursor: pointer;
	}
	&:focus,
	&:active {
		outline: none;
	}
	&:active {
		background-color: ${(props) => getFocusBackgroundColor(props)};
		color: ${(props) => getHoverColor(props)};
	}
	&:disabled {
		pointer-events: none;
		color: ${(props) => getColorDisabled(props)};
		background-color: ${(props) => getDisabledBackgroundColor(props)};
	}
	i {
		position: relative;
		margin-right: ${SPACES.SMALL};
	}
	span {
		word-break: break-word;
	}
`

const StyledLink = styled(Link)`
	text-decoration: none;
`

// TODO: check theme
const Button: React.FC<IButtonProps> = ({ href, icon, children, ...props }) => {
	if (href) {
		return (
			<StyledLink to={href}>
				<StyledButton as="div" {...props}>
					{icon && icon}
					<span>{children}</span>
				</StyledButton>
			</StyledLink>
		)
	}
	return (
		<StyledButton {...props}>
			{icon && icon}
			<span>{children}</span>
		</StyledButton>
	)
}

Button.defaultProps = {
	type: 'button',
}

export default Button
