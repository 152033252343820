import { useEffect, useState } from 'react'
import styled from 'styled-components'

import ReportPhishingButton from '../components/ReportPhishingButton'
import useGenerateToken from '../hooks/useGenerateToken'
import TokenField from '../components/TokenField'
import EmailField from '../components/EmailField'
import useCheckToken from '../hooks/useCheckToken'
import { EMAIL_STORAGE_ITEM, TOKEN_STORAGE_ITEM } from '../config/constants'
import FooterItem from '../components/FooterItem'

interface IFields {
	email: string
}

interface IVerificationFields {
	token: string
}

const MainContent = () => {
	const [authenticated, setAuthenticated] = useState(false)
	const [enteredEmail, setEnteredEmail] = useState(localStorage.getItem(EMAIL_STORAGE_ITEM))

	const { generateToken, errorGeneratingToken } = useGenerateToken()
	const { checkToken, errorCheckingToken } = useCheckToken()

	useEffect(() => {
		async function authenticate() {
			const token = localStorage.getItem(TOKEN_STORAGE_ITEM)

			setAuthenticated(!!token)
		}

		authenticate()
	}, [])

	const handleGenerateToken = (data: IFields) => {
		generateToken(data, {
			onSuccess: () => {
				localStorage.setItem(EMAIL_STORAGE_ITEM, data.email)
				setEnteredEmail(data.email)
			},
		})
	}

	const handleInsertToken = ({ token }: IVerificationFields) => {
		if (!enteredEmail) return

		checkToken(
			{ token, email: enteredEmail },
			{
				onSuccess: () => {
					localStorage.setItem(TOKEN_STORAGE_ITEM, token)
					setAuthenticated(true)
				},
			},
		)
	}

	const resetAuthentication = () => {
		localStorage.removeItem(TOKEN_STORAGE_ITEM)
		localStorage.removeItem(EMAIL_STORAGE_ITEM)
		setAuthenticated(false)
		setEnteredEmail(null)
	}

	if (authenticated) {
		return (
			<Container>
				<ReportPhishingButton
					enteredEmail={enteredEmail}
					resetAuthentication={resetAuthentication}
				/>
				<Footer>
					<FooterItem
						title={'How it works?'}
						subtitle={
							'Click the “Report phishing” button to report any suspicious activity in your inbox. We’ll immediately take the necessary action to process the information and keep you safe.'
						}
					/>{' '}
					<FooterItem
						title={'Experiencing an issue?'}
						subtitle={
							'Try reloading the page and trying again. If the problem isn’t solved, feel free to contact us for assistance.'
						}
					/>
				</Footer>
			</Container>
		)
	}

	if (enteredEmail) {
		return (
			<Container>
				<TokenField
					handleInsertToken={handleInsertToken}
					enteredEmail={enteredEmail}
					setEnteredEmail={setEnteredEmail}
					errorCheckingToken={errorCheckingToken}
				/>
				<Footer>
					<FooterItem
						title={'How it works?'}
						subtitle={
							"Simply paste the token you received in your email into the provided field, then click 'Submit' to activate the 'report phishing' option."
						}
					/>
				</Footer>
			</Container>
		)
	}

	return (
		<Container>
			<EmailField
				handleGenerateToken={handleGenerateToken}
				errorGeneratingToken={errorGeneratingToken}
			/>
			<Footer>
				<FooterItem
					title={'How it works?'}
					subtitle={
						'Provide your email address to receive a secure access token in your inbox. Then use the token to confirm your identity in the next step.'
					}
				/>
			</Footer>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
`

const Footer = styled.div`
	background: #5855fd0a;
	padding: 16px;
	position: absolute;
	bottom: 0;
`

export default MainContent
