import MainContent from './pages/MainContent'
import { setBaseUrl } from './utils/axios'
import { API_BASE_URL } from './config/constants'

const App = () => {
	setBaseUrl(API_BASE_URL)

	return (
		<div className="App" id="popup">
			<MainContent />
		</div>
	)
}

export default App
