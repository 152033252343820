import { useMutation } from 'react-query'

import {
	createReportPhishingEvent as createReportPhishingEventApi,
	CREATE_REPORT_PHISHING_EVENT_KEY,
} from '../utils/api'

const useCreateReportedPhishingEvent = () => {
	const {
		mutate: createReportPhishingEvent,
		isLoading: isCreatingReportPhishingEvent,
		data: data,
	} = useMutation(createReportPhishingEventApi, {
		mutationKey: CREATE_REPORT_PHISHING_EVENT_KEY,
	})

	return {
		createReportPhishingEvent,
		isCreatingReportPhishingEvent,
		data,
	}
}

export default useCreateReportedPhishingEvent
