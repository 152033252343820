import styled from 'styled-components'
import { useState } from 'react'
import { Edit12Filled } from '@fluentui/react-icons'

import Title from './Title'
import Button from './Button'
import { EMAIL_STORAGE_ITEM } from '../config/constants'
import Input from './Input'
import Tooltip from './Tooltip'
import Alert from './Alert'

interface IFields {
	token: string
}

interface IProps {
	enteredEmail: string
	errorCheckingToken: unknown
	handleInsertToken: (data: IFields) => void
	setEnteredEmail: (data: string | null) => void
}

const TokenField: React.FC<IProps> = ({
	handleInsertToken,
	enteredEmail,
	setEnteredEmail,
	errorCheckingToken,
}) => {
	const [formData, setFormData] = useState({ token: '' })
	const handleChangeEmail = () => {
		localStorage.removeItem(EMAIL_STORAGE_ITEM)
		setEnteredEmail(null)
	}
	return (
		<Container>
			<>{errorCheckingToken && <Alert type="error" message="Invalid token!" timeout={5000} />}</>
			<StyledParagraph>
				{enteredEmail}
				<Tooltip content="Change email">
					<Button small transparent onClick={handleChangeEmail} padding="0 5px" margin="0 5px">
						<Edit12Filled style={{ width: '15px', height: '15px' }} />
					</Button>
				</Tooltip>
			</StyledParagraph>
			<FormWrapper>
				<Title text={'Enter token below'} />
				<form
					name="basic"
					onSubmit={(e) => {
						e.preventDefault()
						handleInsertToken(formData)
					}}
					autoComplete="off"
				>
					<Input
						type="text"
						name="token"
						value={formData.token}
						onChange={(e) => setFormData({ token: e.target.value })}
						required
						placeholder="Token"
						defaultValue=""
					/>

					<ButtonWrapper>
						<Button primary type="submit">
							Submit
						</Button>
					</ButtonWrapper>
				</form>
			</FormWrapper>
		</Container>
	)
}

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const Container = styled.div`
	position: absolute;
	top: 100px;
	width: 100%;
`

const StyledParagraph = styled.p`
	font-size: 14px;
	font-weight: 300;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: center;
`

const FormWrapper = styled.div`
	margin-top: 48px;
`

export default TokenField
