import { createRoot } from 'react-dom/client'
import { FluentProvider, webLightTheme } from '@fluentui/react-components'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App'

const rootElement: HTMLElement = document.getElementById('container')
const root = createRoot(rootElement)

const queryClient = new QueryClient({
	defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } },
})

/* Render application after Office initializes */
Office.onReady(() => {
	root.render(
		<FluentProvider theme={webLightTheme}>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</FluentProvider>,
	)
})

if ((module as any).hot) {
	;(module as any).hot.accept('./App', () => {
		const NextApp = require('./App').default
		root.render(NextApp)
	})
}
