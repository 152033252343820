import axios from 'axios'

import { API_PHISHING_URL } from '../config/constants'

export const REPORT_PHISHING_KEY = 'reportPhishing'
export const reportPhishing = async (data: { html: string; token: string }) => {
	const response = await axios.post(`${API_PHISHING_URL}/extension-reports`, data)

	return response.data
}

export const GENERATE_TOKEN_KEY = 'generateToken'
export const generateToken = async (data: { email: string }) => {
	const response = await axios.post(`${API_PHISHING_URL}/extension-reports/generate-token`, data)

	return response.data
}

export const CHECK_TOKEN_KEY = 'checkToken'
export const checkToken = async (data: { email: string; token: string }) => {
	const response = await axios.post(`${API_PHISHING_URL}/extension-reports/check-token`, data)

	return response.data
}

export const CREATE_REPORT_PHISHING_EVENT_KEY = 'createReportPhishingEvent'
export const createReportPhishingEvent = async (token: string) => {
	const response = await axios.get(
		`${API_PHISHING_URL}/events/tracking?event=reported_phishing&token=${token}`,
	)

	return response.data
}
