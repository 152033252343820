import { useState } from 'react'
import styled from 'styled-components'
import { Edit12Filled } from '@fluentui/react-icons'

import useReportPhishing from '../hooks/useReportPhishing'
import Title from './Title'
import Button from './Button'
import { TOKEN_STORAGE_ITEM } from '../config/constants'
import { checkIfTokenInHTML } from '../utils/checkIfTokenInHTML'
import useCreateReportedPhishingEvent from '../hooks/useCreateReportedPhishingEvent'
import Alert from './Alert'
import Tooltip from './Tooltip'

interface IProps {
	enteredEmail: string | null
	resetAuthentication: () => void
}

const ReportPhishingButton: React.FC<IProps> = ({ enteredEmail, resetAuthentication }) => {
	const [isSuccess, setIsSuccess] = useState(false)
	const { reportPhishing } = useReportPhishing()
	const { createReportPhishingEvent } = useCreateReportedPhishingEvent()

	const handleButtonClick = async () => {
		const item = Office.context.mailbox.item
		const coercionType = Office.CoercionType.Html

		item.body.getAsync(coercionType, function (result) {
			if (result.status === Office.AsyncResultStatus.Succeeded) {
				const html = result.value

				const token = localStorage.getItem(TOKEN_STORAGE_ITEM)

				if (token) {
					const tokenInHtml = checkIfTokenInHTML(html)

					if (!!tokenInHtml) {
						createReportPhishingEvent(tokenInHtml)
					}

					reportPhishing(
						{ html, token },
						{
							onSuccess: () => {
								setIsSuccess(true)
								setTimeout(() => {
									setIsSuccess(false)
								}, 5000)
							},
						},
					)
				}
			} else {
				console.error('Error getting email body:', result.error.message)
			}
		})
	}

	return (
		<>
			{isSuccess && <Alert type="success" message="Phishing reported successfully!" />}
			<Wrapper>
				<StyledParagraph>
					{enteredEmail}
					<Tooltip content="Change email">
						<Button small transparent onClick={resetAuthentication} padding="0 5px" margin="0 5px">
							<Edit12Filled style={{ width: '15px', height: '15px' }} />
						</Button>
					</Tooltip>
				</StyledParagraph>
				<ButtonWrapper>
					<Title text={'You’re all set!'} />
					<Button onClick={handleButtonClick} primary>
						Report phishing
					</Button>
				</ButtonWrapper>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 250px;
	position: absolute;
	top: 100px;
	width: 100%;
`

const ButtonWrapper = styled.div`
	margin: 32px auto 16px;
`

const StyledParagraph = styled.p`
	font-size: 14px;
	font-weight: 300;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: center;
`

export default ReportPhishingButton
