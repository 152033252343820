import styled from 'styled-components'

interface IProps {
	text: string
}

const Title: React.FC<IProps> = ({ text }) => {
	return <StyledTitle>{text}</StyledTitle>
}

const StyledTitle = styled.p`
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: center;
`

export default Title
