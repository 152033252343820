import { useMutation } from 'react-query'

import { checkToken as checkTokenApi, CHECK_TOKEN_KEY } from '../utils/api'

const useCheckToken = () => {
	const {
		mutate: checkToken,
		isLoading: isCheckingToken,
		data: data,
		error: errorCheckingToken,
	} = useMutation(checkTokenApi, {
		mutationKey: CHECK_TOKEN_KEY,
	})

	return {
		checkToken,
		isCheckingToken,
		data,
		errorCheckingToken,
	}
}

export default useCheckToken
