export enum Colors {
	Black = '#001433',
	BlackLight = '#222740',
	Dark = '#263767',
	DarkDest = '#384466',
	Blue = '#1581FF',
	Gray = '#808FAD',
	Light = '#D2D8E0',
	GrayHover = '#E1E5EB',
	GrayLighter = '#EDEFF2',
	GrayDark = '#D3D8E0',
	BackgroundGray = '#F0F3F7',
	White = '#FFF',
	Red = '#FF3148',
	Yellow = '#FFE628',
	Orange = '#FF7600',
	Green = '#23C7B6',
	Violet = '#AF5FFF',
}
