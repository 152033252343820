import { useState } from 'react'
import styled from 'styled-components'

import Alert from './Alert'
import Button from './Button'
import Input from './Input'
import Title from './Title'

interface IFields {
	email: string
}

interface IProps {
	handleGenerateToken: (data: IFields) => void
	errorGeneratingToken: unknown
}

const EmailField: React.FC<IProps> = ({ handleGenerateToken, errorGeneratingToken }) => {
	const [formData, setFormData] = useState<IFields>({ email: '' })

	return (
		<Container>
			<>
				{errorGeneratingToken && (
					<Alert type="error" message="Entered email not found!" timeout={5000} />
				)}
			</>

			<FormWrapper>
				<Title text={'Send token'} />
				<form
					name="basic"
					onSubmit={(e) => {
						e.preventDefault()
						handleGenerateToken(formData)
					}}
					autoComplete="off"
				>
					<Input
						type="email"
						name="email"
						value={formData.email}
						onChange={(e) => setFormData({ email: e.target.value })}
						required
						placeholder="Email address"
						defaultValue=""
					/>

					<ButtonWrapper>
						<Button primary type="submit">
							Login
						</Button>
					</ButtonWrapper>
				</form>
			</FormWrapper>
		</Container>
	)
}

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`

const Container = styled.div`
	padding: 7px;
	position: absolute;
	top: 100px;
`

const FormWrapper = styled.div`
	margin-top: 60px;
`

export default EmailField
