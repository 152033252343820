import { useMutation } from 'react-query'

import { reportPhishing as reportPhishingApi, REPORT_PHISHING_KEY } from '../utils/api'

const useReportPhishing = () => {
	const {
		mutate: reportPhishing,
		isLoading: isReportingPhishing,
		data: html,
	} = useMutation(reportPhishingApi, {
		mutationKey: REPORT_PHISHING_KEY,
	})

	return {
		reportPhishing,
		isReportingPhishing,
		html,
	}
}

export default useReportPhishing
