import styled from 'styled-components'

interface IProps {
	children: React.ReactNode | React.ReactNode[]
	content: string
}

const Tooltip: React.FC<IProps> = ({ children, content }) => {
	return (
		<TooltipContainer>
			{children}
			<TooltipContent className="tooltip-content">{content}</TooltipContent>
		</TooltipContainer>
	)
}

const TooltipContainer = styled.div`
	position: relative;
	display: inline-block;
	cursor: pointer;

	&:hover .tooltip-content {
		opacity: 1;
		display: block;
	}
`

const TooltipContent = styled.div`
	display: none;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #333;
	color: white;
	padding: 8px;
	border-radius: 4px;
	white-space: nowrap;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
`

export default Tooltip
